import GenericButton from "../../components/GenericButton";
import GuildListEntry from "../../components/GuildListEntry";
import RedBox from "../../components/RedBox";
import SectionHeader from "../../components/SectionHeader";

import AddGuild from "../../assets/addGuild.svg";
import FiltersIcon from "../../assets/Filters.svg";
import GuildIcon from "../../assets/MyGuild.svg";

import "./styles.scss";
import { useGuilds } from "../../contexts/guilds";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useGuild } from "../../contexts/guild";
import { useUser } from "../../contexts/user";
import FilterModal from "../../components/FilterModal";
import CountdownBox from "../../components/CountdownBox";
import { getNextEvent } from "../../utils/events";

const MainSection = () => {
  const guilds = useGuilds();
  const guild = useGuild();
  const navigate = useNavigate();
  const user = useUser();

  const [showFilters, setShowFilters] = useState(false);

  const sortedGuilds = Object.values(guilds.guilds).sort(
    (a, b) => (a.ranking || 0) - (b.ranking || 0)
  );

  const ownGuild: string | null =
    user.user?.membershipEnt?.find((ent) => ent.role === "OWNER")?.urlSlug ||
    null;

  const loadMore = () => {
    guilds.loadNextPage();
  };

  const goToGuild = () => {
    guild.forceLoading();
    if (ownGuild) {
      navigate(`/guilds/${ownGuild}`);
    } else {
      navigate("/guild-create");
    }
  };

  useEffect(() => {
    loadMore();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const nextEvent = getNextEvent();

  return (
    <div className="main-section">
      <SectionHeader
        title="Diablo 4 Guilds & Clans"
        subtitle="Diablo4Guild.com - Diablo 4 Guild and Clans - search for Sanctuary's best guilds and clans. Search and filter by names, platforms, language, and builds. Create your own clan page, add your guild tag, official Discord, description, guild logo, platforms (PC, Xbox, PlayStation), and invite others to join!"
        rightSection={
          <div className="header-centered-right-section">
            <GenericButton
              label={ownGuild ? "My Guild" : "Add Guild"}
              icon={ownGuild ? GuildIcon : AddGuild}
              withGlow
              onClick={goToGuild}
            />
          </div>
        }
      />
      {nextEvent && (
        <CountdownBox
          title={`Upcoming Event \u2013 ${nextEvent.name}`}
          targetDate={nextEvent.date}
        />
      )}
      <RedBox
        title="Ranking"
        rightAction={
          <GenericButton
            label="Filters"
            icon={FiltersIcon}
            onClick={() => setShowFilters(true)}
          />
        }
      >
        {sortedGuilds.map((guild) => (
          <GuildListEntry guild={guild} key={guild.id} />
        ))}
      </RedBox>
      {guilds.hasMore && (
        <div className="load-more-container">
          <GenericButton
            label="Load More"
            onClick={guilds.loadNextPage}
            loading={guilds.loading}
            whiteLoading
          />
        </div>
      )}
      {showFilters && <FilterModal onClose={() => setShowFilters(false)} />}
    </div>
  );
};

export default MainSection;
